import { put, select, takeLatest, delay } from 'redux-saga/effects';
import axios from 'axios';
import { isMobile } from 'mobile-device-detect';
import {
  SEARCH_CIGARS,
  SEARCH_VENUES,
  SEARCH_BRANDS,
  SEARCH_GROUPS,
  SEARCH_PRODUCTS,
  SEARCH_USERS,
  searchCigars,
  searchCigarsError,
  searchCigarsSuccess,
  searchProducts,
  searchProductsError,
  searchProductsSuccess,
  searchUsers,
  searchUsersError,
  searchUsersSuccess,
  searchVenues,
  searchVenuesError,
  searchVenuesSuccess,
  searchBrands,
  searchBrandsError,
  searchBrandsSuccess,
  searchGroups,
  searchGroupsError,
  searchGroupsSuccess,
} from './actions';
import {
  selectCigarsLoading,
  selectVenuesLoading,
  selectGroupsLoading,
  selectProductsLoading,
  selectUsersLoading,
  selectBrandsLoading,
} from './selectors';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

// FIXME Get something like this working - rare, but I still see some "old" results override the correct ones due to server response times
// let lastSearchTerm;

/**
 * Search for cigars based on the query
 * @param action
 */
export function* loadCigarResultsRequest(action) {
  const { query } = action;

  // yield call(delay, 300);

  const isLoading = yield select(selectCigarsLoading());
  // if (isLoading) {
  //   return;
  // }

  // FIXME For some reason, this makes the results more consistent on desktop but isn't necessary on mobile
  if (!isMobile) {
    yield delay(200);
  }
  yield put(searchCigars(query));
  // lastSearchTerm = query;
  try {
    const response = yield axios.get(`${config.apiEndPoint}/cigars/search`, {
      params: {
        q: query,
        limit: 50, // TODO Limit this even more? Expand more?
      },
    });
    // if (lastSearchTerm === query) {
    console.log('Cigar response:');
    console.log(response);
    const cigars = response.data || [];
    yield put(searchCigarsSuccess(cigars));
    // }
  } catch (error) {
    yield put(searchCigarsError(error));
  }
}

export function* loadCigarResultsWatcher() {
  yield takeLatest(SEARCH_CIGARS.REQUEST, loadCigarResultsRequest);
}

/**
 * Search for venues based on the query
 * @param action
 */
export function* loadVenueResultsRequest(action) {
  const { query } = action;

  const isLoading = yield select(selectVenuesLoading());
  // if (isLoading) {
  //   return;
  // }

  // yield delay(200);
  yield put(searchVenues(query));
  // lastSearchTerm = query;
  try {
    const response = yield axios.get(`${config.apiEndPoint}/venues/search`, {
      params: {
        q: query,
        limit: 50, // TODO Limit this even more?
      },
    });
    // if (lastSearchTerm === query) {
    const venues = response.data || [];
    yield put(searchVenuesSuccess(venues));
    // }
  } catch (error) {
    yield put(searchVenuesError(error));
  }
}

export function* loadVenueResultsWatcher() {
  yield takeLatest(SEARCH_VENUES.REQUEST, loadVenueResultsRequest);
}

/**
 * Search for brands based on the query
 * @param action
 */
export function* loadBrandResultsRequest(action) {
  const { query } = action;

  const isLoading = yield select(selectBrandsLoading());
  // if (isLoading) {
  //   return;
  // }

  // yield delay(200);
  yield put(searchBrands(query));
  // lastSearchTerm = query;
  try {
    const response = yield axios.get(`${config.apiEndPoint}/brands/searchnvm us e 14`, {
      params: {
        q: query,
        limit: 50, // TODO Limit this even more?
      },
    });
    // if (lastSearchTerm === query) {
    const venues = response.data || [];
    yield put(searchBrandsSuccess(venues));
    // }
  } catch (error) {
    yield put(searchBrandsError(error));
  }
}

export function* loadBrandResultsWatcher() {
  yield takeLatest(SEARCH_BRANDS.REQUEST, loadBrandResultsRequest);
}

/**
 * Search for groups based on the query
 * @param action
 */
export function* loadGroupResultsRequest(action) {
  const { query } = action;

  const isLoading = yield select(selectGroupsLoading());
  // if (isLoading) {
  //   return;
  // }

  // yield delay(200);
  yield put(searchGroups(query));
  // lastSearchTerm = query;
  try {
    const response = yield axios.get(`${config.apiEndPoint}/groups/search`, {
      params: {
        q: query,
        limit: 50, // TODO Limit this even more?
      },
    });
    // if (lastSearchTerm === query) {
    const groups = response.data || [];
    yield put(searchGroupsSuccess(groups));
    // }
  } catch (error) {
    yield put(searchGroupsError(error));
  }
}

export function* loadGroupResultsWatcher() {
  yield takeLatest(SEARCH_GROUPS.REQUEST, loadGroupResultsRequest);
}

/**
 * Search for products based on the query
 * @param action
 */
export function* loadProductResultsRequest(action) {
  const { query } = action;

  const isLoading = yield select(selectProductsLoading());
  // if (isLoading) {
  //   return;
  // }

  // yield delay(200);
  yield put(searchProducts(query));
  // lastSearchTerm = query;
  try {
    const response = yield axios.get(`${config.shopEndPoint}/products/search`, {
      params: {
        q: query,
        limit: 50, // TODO Limit this even more?
      },
    });
    // if (lastSearchTerm === query) {
    const products = response.data || [];
    yield put(searchProductsSuccess(products));
    // }
  } catch (error) {
    yield put(searchProductsError(error));
  }
}

export function* loadProductResultsWatcher() {
  yield takeLatest(SEARCH_PRODUCTS.REQUEST, loadProductResultsRequest);
}

/**
 * Search for users based on the query
 * @param action
 */
export function* loadUserResultsRequest(action) {
  const { query } = action;

  const isLoading = yield select(selectUsersLoading());
  // if (isLoading) {
  //   return;
  // }

  // yield delay(200);
  yield put(searchUsers(query));
  // lastSearchTerm = query;
  try {
    const response = yield axios.get(`${config.apiEndPoint}/users/search`, {
      params: {
        q: query,
        limit: 50, // TODO Limit this even more?
      },
    });
    // if (lastSearchTerm === query) {
    const users = response.data || [];
    yield put(searchUsersSuccess(users));
    // }
  } catch (error) {
    yield put(searchUsersError(error));
  }
}

export function* loadUserResultsWatcher() {
  yield takeLatest(SEARCH_USERS.REQUEST, loadUserResultsRequest);
}

export default [
  loadCigarResultsWatcher,
  loadVenueResultsWatcher,
  loadGroupResultsWatcher,
  loadUserResultsWatcher,
  loadProductResultsWatcher,
  loadBrandResultsWatcher,
];
