import { createSelector } from 'reselect';
import { fromJS } from 'immutable';

export const selectSearchMapping = () => (state) => state.getIn(['search']);

export const selectSearchState = () => createSelector(
  selectSearchMapping(),
  (searchState) => searchState.get('search_results', fromJS({
    cigarsLoading: false,
    venuesLoading: false,
    brandsLoading: false,
    productsLoading: false,
    usersLoading: false,
    cigarsErrorMessage: '',
    venuesErrorMessage: '',
    brandsErrorMessage: '',
    productsErrorMessage: '',
    usersErrorMessage: '',
    cigars: null,
    venues: null,
    brands: null,
    products: null,
    users: null,
  }))
);

// Cigars

export const selectCigarsLoading = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('cigarsLoading'),
);

export const selectCigarsErrorMessage = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('cigarsErrorMessage'),
);

export const selectCigarResults = () => createSelector(
  selectSearchState(),
  (searchState) => {
    console.log('Search state:');
    console.log(searchState.get('cigars'));
    return searchState.get('cigars');
  },
);

// Groups

export const selectGroupsLoading = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('groupsLoading'),
);

export const selectGroupsErrorMessage = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('groupsErrorMessage'),
);

export const selectGroupResults = () => createSelector(
  selectSearchState(),
  (searchState) => {
    console.log('Search state:');
    console.log(searchState);
    return searchState.get('groups');
  },
);

// Venues

export const selectVenuesLoading = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('venuesLoading'),
);

export const selectVenuesErrorMessage = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('venuesErrorMessage'),
);

export const selectVenueResults = () => createSelector(
  selectSearchState(),
  (searchState) => {
    console.log('Search state:');
    console.log(searchState);
    return searchState.get('venues');
  },
);

// Brands

export const selectBrandsLoading = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('brandsLoading'),
);

export const selectBrandsErrorMessage = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('brandsErrorMessage'),
);

export const selectBrandResults = () => createSelector(
  selectSearchState(),
  (searchState) => {
    console.log('Search state:');
    console.log(searchState.get('brands'));
    return searchState.get('brands');
  },
);

// Products

export const selectProductsLoading = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('productsLoading'),
);

export const selectProductsErrorMessage = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('productsErrorMessage'),
);

export const selectProductResults = () => createSelector(
  selectSearchState(),
  (searchState) => {
    console.log('Search state:');
    console.log(searchState);
    return searchState.get('products');
  },
);

// Users

export const selectUsersLoading = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('usersLoading'),
);

export const selectUsersErrorMessage = () => createSelector(
  selectSearchState(),
  (searchState) => searchState.get('usersErrorMessage'),
);

export const selectUserResults = () => createSelector(
  selectSearchState(),
  (searchState) => {
    console.log('Search state:');
    console.log(searchState.toJS());
    return searchState.get('users');
  },
);
