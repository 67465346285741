import { fromJS } from 'immutable';
import {
  SEARCH_CIGARS,
  SEARCH_VENUES,
  SEARCH_BRANDS,
  SEARCH_GROUPS,
  SEARCH_PRODUCTS,
  SEARCH_USERS,
} from './actions';

const listState = fromJS({
  cigarsLoading: false,
  venuesLoading: false,
  brandsLoading: false,
  groupsLoading: false,
  productsLoading: false,
  usersLoading: false,
  cigarsErrorMessage: '',
  venuesErrorMessage: '',
  brandsErrorMessage: '',
  groupsErrorMessage: '',
  productsErrorMessage: '',
  usersErrorMessage: '',
  cigars: null,
  venues: null,
  brands: null,
  groups: null,
  products: null,
  users: null,
});
export function listReducer(state = listState, action) {
  switch (action.type) {
    case SEARCH_CIGARS.FETCH: {
      return state.set('cigarsLoading', true);
    }

    case SEARCH_CIGARS.SUCCESS: {
      const { cigars } = action;
      console.log('Cigars from search:');
      console.log(cigars);
      return state
        .set('cigarsLoading', false)
        .set('cigars', cigars);
    }

    case SEARCH_CIGARS.FAILURE: {
      return state
        .set('cigarsLoading', false)
        .set('cigarsErrorMessage', action.error.message);
    }

    case SEARCH_CIGARS.CANCEL: {
      return state.set('cigarsLoading', false);
    }

    case SEARCH_VENUES.FETCH: {
      return state.set('venuesLoading', true);
    }

    case SEARCH_VENUES.SUCCESS: {
      const { venues } = action;
      console.log('Venues from search:');
      console.log(venues);
      return state
        .set('venuesLoading', false)
        .set('venues', venues);
    }

    case SEARCH_VENUES.FAILURE: {
      return state
        .set('venuesLoading', false)
        .set('venuesErrorMessage', action.error.message);
    }

    case SEARCH_VENUES.CANCEL: {
      return state.set('venuesLoading', false);
    }

    case SEARCH_BRANDS.FETCH: {
      return state.set('brandsLoading', true);
    }

    case SEARCH_BRANDS.SUCCESS: {
      const { brands } = action;
      console.log('Brands from search:');
      console.log(brands);
      return state
        .set('brandsLoading', false)
        .set('brands', brands);
    }

    case SEARCH_BRANDS.FAILURE: {
      return state
        .set('brandsLoading', false)
        .set('brandsErrorMessage', action.error.message);
    }

    case SEARCH_BRANDS.CANCEL: {
      return state.set('brandsLoading', false);
    }

    case SEARCH_GROUPS.FETCH: {
      return state.set('groupsLoading', true);
    }

    case SEARCH_GROUPS.SUCCESS: {
      const { groups } = action;
      console.log('Groups from search:');
      console.log(groups);
      return state
        .set('groupsLoading', false)
        .set('groups', groups);
    }

    case SEARCH_GROUPS.FAILURE: {
      return state
        .set('groupsLoading', false)
        .set('groupsErrorMessage', action.error.message);
    }

    case SEARCH_GROUPS.CANCEL: {
      return state.set('groupsLoading', false);
    }

    case SEARCH_PRODUCTS.FETCH: {
      return state.set('productsLoading', true);
    }

    case SEARCH_PRODUCTS.SUCCESS: {
      const { products } = action;
      console.log('Products from search:');
      console.log(products);
      return state
        .set('productsLoading', false)
        .set('products', products);
    }

    case SEARCH_PRODUCTS.FAILURE: {
      return state
        .set('productsLoading', false)
        .set('productsErrorMessage', action.error.message);
    }

    case SEARCH_PRODUCTS.CANCEL: {
      return state.set('productsLoading', false);
    }

    case SEARCH_USERS.FETCH: {
      return state.set('usersLoading', true);
    }

    case SEARCH_USERS.SUCCESS: {
      const { users } = action;
      console.log('Users from search:');
      console.log(users);
      return state
        .set('usersLoading', false)
        .set('users', users);
    }

    case SEARCH_USERS.FAILURE: {
      return state
        .set('usersLoading', false)
        .set('usersErrorMessage', action.error.message);
    }

    case SEARCH_USERS.CANCEL: {
      return state.set('usersLoading', false);
    }

    default:
      return state;
  }
}

export default function listMappingReducer(state = listState, action) {
  switch (action.type) {
    case SEARCH_CIGARS.FETCH:
    case SEARCH_CIGARS.SUCCESS:
    case SEARCH_CIGARS.FAILURE:
    case SEARCH_VENUES.FETCH:
    case SEARCH_VENUES.SUCCESS:
    case SEARCH_VENUES.FAILURE:
    case SEARCH_BRANDS.FETCH:
    case SEARCH_BRANDS.SUCCESS:
    case SEARCH_BRANDS.FAILURE:
    case SEARCH_GROUPS.FETCH:
    case SEARCH_GROUPS.SUCCESS:
    case SEARCH_GROUPS.FAILURE:
    case SEARCH_PRODUCTS.FETCH:
    case SEARCH_PRODUCTS.SUCCESS:
    case SEARCH_PRODUCTS.FAILURE:
    case SEARCH_USERS.FETCH:
    case SEARCH_USERS.SUCCESS:
    case SEARCH_USERS.FAILURE: {
      console.log('Updating search results...');
      const selectedList = state.get('search_results');
      const updatedList = listReducer(selectedList, action);
      return state.set('search_results', updatedList);
    }

    default:
      return state;
  }
}
