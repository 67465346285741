import { createAsyncAction } from '../../utils/actionUtils';

export const SEARCH_CIGARS = createAsyncAction('SEARCH_CIGARS');
export const SEARCH_VENUES = createAsyncAction('SEARCH_VENUES');
export const SEARCH_BRANDS = createAsyncAction('SEARCH_BRANDS');
export const SEARCH_GROUPS = createAsyncAction('SEARCH_GROUPS');
export const SEARCH_USERS = createAsyncAction('SEARCH_USERS');
export const SEARCH_PRODUCTS = createAsyncAction('SEARCH_PRODUCTS');

export function requestSearchCigars(query) {
  return {
    type: SEARCH_CIGARS.REQUEST,
    query,
  };
}

export function searchCigars(query) {
  return {
    type: SEARCH_CIGARS.FETCH,
    query,
  };
}

export function searchCigarsSuccess(cigars) {
  return {
    type: SEARCH_CIGARS.SUCCESS,
    cigars,
  };
}

export function searchCigarsError(error) {
  return {
    type: SEARCH_CIGARS.FAILURE,
    error,
  };
}

export function requestSearchVenues(query) {
  return {
    type: SEARCH_VENUES.REQUEST,
    query,
  };
}

export function searchVenues(query) {
  return {
    type: SEARCH_VENUES.FETCH,
    query,
  };
}

export function searchVenuesSuccess(venues) {
  return {
    type: SEARCH_VENUES.SUCCESS,
    venues,
  };
}

export function searchVenuesError(error) {
  return {
    type: SEARCH_VENUES.FAILURE,
    error,
  };
}

export function requestSearchBrands(query) {
  return {
    type: SEARCH_BRANDS.REQUEST,
    query,
  };
}

export function searchBrands(query) {
  return {
    type: SEARCH_BRANDS.FETCH,
    query,
  };
}

export function searchBrandsSuccess(cigars) {
  return {
    type: SEARCH_BRANDS.SUCCESS,
    cigars,
  };
}

export function searchBrandsError(error) {
  return {
    type: SEARCH_BRANDS.FAILURE,
    error,
  };
}

export function requestSearchGroups(query) {
  return {
    type: SEARCH_GROUPS.REQUEST,
    query,
  };
}

export function searchGroups(query) {
  return {
    type: SEARCH_GROUPS.FETCH,
    query,
  };
}

export function searchGroupsSuccess(groups) {
  return {
    type: SEARCH_GROUPS.SUCCESS,
    groups,
  };
}

export function searchGroupsError(error) {
  return {
    type: SEARCH_GROUPS.FAILURE,
    error,
  };
}

export function requestSearchUsers(query) {
  return {
    type: SEARCH_USERS.REQUEST,
    query,
  };
}

export function searchUsers(query) {
  return {
    type: SEARCH_USERS.FETCH,
    query,
  };
}

export function searchUsersSuccess(users) {
  return {
    type: SEARCH_USERS.SUCCESS,
    users,
  };
}

export function searchUsersError(error) {
  return {
    type: SEARCH_USERS.FAILURE,
    error,
  };
}

export function requestSearchProducts(query) {
  return {
    type: SEARCH_PRODUCTS.REQUEST,
    query,
  };
}

export function searchProducts(query) {
  return {
    type: SEARCH_PRODUCTS.FETCH,
    query,
  };
}

export function searchProductsSuccess(products) {
  return {
    type: SEARCH_PRODUCTS.SUCCESS,
    products,
  };
}

export function searchProductsError(error) {
  return {
    type: SEARCH_PRODUCTS.FAILURE,
    error,
  };
}
